import * as React from 'react';
import styled from 'styled-components';

const CustomCode = styled.code`
    font-family: monospace;
    float: left;
    width: 100%;
    max-width: 75ch;
    padding: 1rem;
    color: white;
    background-color: #181818;

`


interface CodeProps {
    
}
 
const Code: React.FunctionComponent<CodeProps> = ({ children }) => {
    return ( <CustomCode>{children}</CustomCode> );
}
 
export default Code;