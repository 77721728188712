import * as React from "react";
import { graphql, Link } from "gatsby";
import Layout from "../components/layouts/layout";
import SEO from "../components/seo";
import styled from "styled-components";
import { PrismicRichText } from "@prismicio/react";
import DocsMainNav from "../components/organisms/docsMainNav";
import { LargeP, P } from "../components/atoms/paragraph";
import { H1, H2 } from "../components/atoms/headings";
import { DocStrong } from "../components/atoms/strong";
import Code from "../components/atoms/code";
import { motion } from "framer-motion";
import Arrow from "../assets/arrow";
import { ArrowWrapper } from "../components/organisms/accordion";
// import DocsMainBody from "../components/organisms/docsMainBody";
// import AnchorNav from "../components/organisms/docsAnchorNav";

// styles

const Wrapper = styled.section`
  margin: 80px 0;
  display: grid;
  /* grid-template-rows: 45px auto; */
  grid-template-areas:
    "al"
    "main";
  @media (min-width: 1028px) {
    margin: 120px 0;
    grid-template-columns: 390px 1fr 290px;
    grid-template-rows: auto;
    grid-template-areas: "sb main al";
  }
`;

const BodyWrapper = styled.div`
  grid-area: main;
  margin: 0 auto;
`;

const Container = styled.div`
  padding: 0 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const AnchorWrapper = styled.aside`
  display: none;
  position: fixed;
  white-space: nowrap;
  overflow-x: auto;
  left: 1rem;
  right: 1rem;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  background-color: #ffffff;
  ::-webkit-scrollbar {
    display: none;
  }
  @media (min-width: 1028px) {
    width: 290px;
    left: unset;
    right: 0;
    display: block;
  }
`;

const AnchorContainer = styled.aside`
  display: flex;
  > button:not(:first-child) {
    margin-left: 0.5rem;
  }
  @media (min-width: 1028px) {
    overflow-x: visible;
    align-items: flex-start;
    flex-direction: column;
    > button:not(:first-child) {
      margin-left: 0;
    }
  }
`;

const AnchorTitle = styled.h2`
  scroll-margin-top: 500px;
`;

const AnchorButton = styled.button`
  font-family: acumin-pro-condensed, sans-serif;
  font-weight: 600;
  font-size: 16px;
  letter-spacing: 0.25px;
  margin: 0.25rem 0;
  cursor: pointer;
  background: none;
  width: 100%;
  padding: 0;


  border: none;
  // Tag Style
  border-radius: 1rem;
  text-align: left;

  /* background-color: lightgrey; */
  :hover {
    color: grey;
  }
  @media (min-width: 1028px) {
    border: none;
    width: unset;
    font-size: 13px;

    background-color: #ffffff;
  }
`;

const LinkContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
`
type CustomLinkType = {
  alignSelf: string;
}

// type CustomLinkWrapper = {
//   align: string;
// }

// const CustomLinkWrapper = styled.div<CustomLinkWrapper>`
//   display: flex;
//   justify-content: ${props => props.align};
// `

const CustomLink = styled(Link)<CustomLinkType>`
  place-self: ${props => props.alignSelf};
  font-family: acumin-pro-wide, serif;
  padding: 1rem;
  border: 1px solid lightgrey;
  border-radius: 3px;
  :hover {
    border: 1px solid black;
  }
`

const AccordionWrapper = styled.div`
  display: block;
  margin: 1rem 1rem 3rem 1rem;

  @media(min-width: 1028px) {
    display: none;
  }
`

const AccordionHeader = styled.header<AccordionBodyType>`
  font-family: acumin-pro, serif;
  font-size:1.1rem;
  font-weight: 600;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: lightgrey;
  padding: 1rem;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  border-bottom-left-radius: ${(props) => (props.open ? "0px" : "4px")};
  border-bottom-right-radius: ${(props) => (props.open ? "0px" : "4px")};

`

type AccordionBodyType = {
  open: boolean;
}

const AccordionBody = styled(motion.div)<AccordionBodyType>`
  padding: 0 1rem;
  background-color: lightgrey;
  display: ${(props) => (props.open ? "block" : "none")}; 
  overflow: ${(props) => (props.open ? "visible" : "hidden")}; 
  height:  ${(props) => (props.open ? "auto" : "0")};
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  padding-bottom: 1rem;
`;

const AnchorAccordion = ({open, setOpen, children}) => {
  return(
    <AccordionWrapper>
    <AccordionHeader open={open} onClick={() => setOpen(!open)}><span>On this page</span> <ArrowWrapper open={open}><Arrow /></ArrowWrapper></AccordionHeader>
    <AccordionBody open={open}>{children}</AccordionBody>
    </AccordionWrapper>
  )
}

const Doc = ({ data }) => {
  if (!data) return null;
  const doc = data.prismicDoc;
  const { lang, type, url } = doc || {};
  const alternateLanguages = doc.alternate_languages || [];
  const activeDoc = {
    lang,
    type,
    url,
    alternateLanguages,
  };
  const cntBlcks = doc.data.content_block;
  const [open, setOpen] = React.useState(false);
  const refs = cntBlcks.reduce((acc, value) => {
    acc[value.title.text] = React.createRef();
    return acc;
  }, {});

  const handleClick = (id) =>
    refs[id].current.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });

  return (
    <>
      <Layout type="doc"  activeDocMeta={activeDoc}>
        <SEO
          lang={doc.lang}
          title={`${doc.data.body.map(
            (ele) => ele.primary.seo_title
          )} | CiteDrive Documentation`}
          description={doc.data.body.map((ele) => ele.primary.meta_description)}
          type="doc"
        />
        <Wrapper>

          <DocsMainNav />


          {/* Docs Main Body */}
          <BodyWrapper>

          <AnchorAccordion open={open} setOpen={() => setOpen(!open)}>
          {cntBlcks.map((ele, index) => (
                <AnchorButton
                  type="button"
                  onClick={() => handleClick(ele.title.text)}
                  key={index}
                >
                  {ele.title.text}
                </AnchorButton>
              ))}
          </AnchorAccordion>
            <Container>
              <H1>{doc.data.main_title.text}</H1>
              <LargeP>{doc.data.description.text}</LargeP>
              {cntBlcks.map((ele, i) => (
                <div
                  style={{
                    scrollMarginTop: "calc(120px + 1rem)",
                    // padding: "0 1rem",
                    marginBottom: "2rem",
                  }}
                  ref={refs[ele.title.text]}
                >
                  <H2>{ele.title.text}</H2>
                  <PrismicRichText
                    field={ele.content.richText}
                    components={{
                      paragraph: ({ children, key }) => (
                        <P key={key}>{children}</P>
                      ),
                      strong: ({ children, key}) => (<DocStrong key={key}>{children}</DocStrong>),
                      preformatted: ({ children, key }) => <Code key={key}>{children}</Code>, 
                      // image: ({children, node, key, text, type   }) => <img key={key} />


                      // strong
                    }}
                  />
                </div>
              ))}

              <LinkContainer>

              {doc.data.prev.url &&
 
                   <CustomLink alignSelf="flex-start" to={doc.data.prev.url}>Prev</CustomLink>
  
              }

              {doc.data.next.url &&

                <CustomLink alignSelf="flex-end" to={doc.data.next.url}>Next</CustomLink>

              }

              </LinkContainer>
            </Container>
          </BodyWrapper>
          {/* Anchor Nav */}

          <AnchorWrapper>
            <AnchorContainer>
              {cntBlcks.map((ele, index) => (
                <AnchorButton
                  type="button"
                  onClick={() => handleClick(ele.title.text)}
                  key={index}
                >
                  {ele.title.text}
                </AnchorButton>
              ))}
            </AnchorContainer>
            
          </AnchorWrapper>{" "}



        </Wrapper>
      </Layout>
    </>
  );
};

export default Doc;

export const query = graphql`
  query DocQuery($id: String, $lang: String) {
    prismicDoc(id: { eq: $id }, lang: { eq: $lang }) {
      id
      lang
      url
      tags
      alternate_languages {
        uid
        type
        lang
        id
      }
      data {
        body {
          ... on PrismicDocDataBodyMetaTags {
            id
            primary {
              seo_title
              meta_description
              hashtags
            }
          }
        }
        main_title {
          text
          richText
        }
        description {
          text
          richText
        }
        content_block {
          title {
            text
            richText
          }
          content {
            text
            richText
          }
        }
        next {
          url
          uid
        }
        prev {
          url
          uid
        }
      }
    }
  }
`;
